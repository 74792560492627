<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Head, Link, router, useForm, usePage} from '@inertiajs/vue3'
import { ChevronDownIcon } from '@heroicons/vue/16/solid'
import {BackspaceIcon, BuildingOfficeIcon, CalendarIcon, GlobeAltIcon, UserGroupIcon, UserIcon} from "@heroicons/vue/24/outline";
import {ref} from "vue";
import Input from "../../../Components/Form/Input.vue";
import {RectangleStackIcon, UserCircleIcon} from "@heroicons/vue/24/solid/index.js";
import Select from "../../../Components/Form/Select.vue";
import { inject } from 'vue';
import AddTeamMemberModal from "./AddTeamMemberModal.vue";
import Alert from "../../../Components/Alert.vue";
import axios from "axios";

const page = usePage();
const route = inject('route');

const pageTree = [
    {name: 'settings', href: route('main.settings'), current: true}
]

const props = defineProps({
    settings: {
        type: Object,
        required: true,
    },
    teamMembers: {
        type: Array,
        required: true,
    },
})

let visibleTab = ref('myAccount')

const tabs = ref([
    { name: 'myaccount', tabName: 'myAccount', icon: UserIcon, current: true },
    { name: 'company', tabName: 'companyInfo', icon: BuildingOfficeIcon, current: false },
    { name: 'contact', tabName: 'contactInfo', icon: GlobeAltIcon, current: false },
    { name: 'scheduling', tabName: 'scheduling', icon: CalendarIcon, current: false },
    { name: 'team', tabName: 'team', icon: UserGroupIcon, current: false },
    { name: 'taskManagement', tabName: 'taskManagement', icon: RectangleStackIcon, current: false },
])

const form = useForm({
    schedule_interval: props.settings.schedule_interval,

    tenant_name: props.settings.tenant_name,
    company_name: props.settings.company_name,
    company_address: props.settings.company_address,
    company_city: props.settings.company_city,
    company_state: props.settings.company_state,
    company_zip: props.settings.company_zip,
    company_phone: props.settings.company_phone,
    company_email: props.settings.company_email,
    company_website: props.settings.company_website,
    company_fiscal_code: props.settings.company_fiscal_code,
    company_vat_code: props.settings.company_vat_code,
    company_registration_number: props.settings.company_registration_number,
    company_facebook: props.settings.company_facebook,
    company_twitter: props.settings.company_twitter,
    company_instagram: props.settings.company_instagram,
    company_linkedin: props.settings.company_linkedin,
    company_youtube: props.settings.company_youtube,
    currency: props.settings.currency,
    country: props.settings.country,
    logo: props.settings.logo,
    tasks_remind_before_deadline_days: props.settings.tasks_remind_before_deadline_days,
    tasks_hide_completed_tasks_days: props.settings.tasks_hide_completed_tasks_days,
    _method: 'patch',

});

function setActiveTab(tabName) {
    visibleTab.value = tabName
    tabs.value.forEach(tab => {
        tab.current = tab.tabName === tabName
    })
}

function submit() {
    form.post(route('main.settings.patch'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.settings'))
        }
    })
}


let imageUrl = ref('')
imageUrl.value=props.settings.logo ? props.settings.logo : ''
function photoUploaded(files) {
    form.logo = files[0]
    imageUrl.value = URL.createObjectURL(files[0])
}


const countries = [
    {value: 'RO', label: 'România'},
]

const addTeamMemberModalOpen = ref(false)

function openAddTeamMemberModal() {
    addTeamMemberModalOpen.value = true
}

function closeAddTeamMemberModal() {
    addTeamMemberModalOpen.value = false
}

function teamMemberAdded(member) {
    props.teamMembers.push(member)
}


let deleteModalOpen = ref(false)
let userIdToRemove = 0

function openRemoveModal(userId) {
    userIdToRemove = userId
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function removeTeamMember() {
    closeRemoveModal()
    axios.delete(route('main.settings.remove.team.member',{userId: userIdToRemove}))
        .then(() => {
            props.teamMembers.forEach((member, index) => {
                if (member.user.id === userIdToRemove) {
                    props.teamMembers.splice(index, 1)
                }
            })
        })
        .catch(error => {
            console.error(error)
        })
}
</script>

<template>
    <Head title="Settings"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <div class="flex flex-row">
            <div class="grid grid-cols-1 sm:hidden">
                <select aria-label="Select a tab" class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600" @change="setActiveTab($event.target.value)">
                    <option v-for="tab in tabs" :key="tab.name" :selected="tab.current" :value="tab.tabName">{{ $t(tab.name) }}</option>
                </select>
                <ChevronDownIcon class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500" aria-hidden="true" />
            </div>
            <div class="hidden sm:block">
                <div class="border-b border-gray-200">
                    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                        <button v-for="tab in tabs" :key="tab.name" :class="[tab.current ? 'border-gray-100 text-gray-200' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-400', 'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium']" :aria-current="tab.current ? 'page' : undefined" @click="setActiveTab(tab.tabName)">
                            <component :is="tab.icon" :class="[tab.current ? 'text-gray-100' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 size-5']" aria-hidden="true" />
                            <span>{{ $t(tab.name) }}</span>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
        <form @submit.prevent="submit">
<!--            My account tab-->
            <div class="space-y-12" v-if="visibleTab === 'myAccount'">
                <div class="border-b border-white/10 pb-12">
                    <p class="mt-1 text-sm/6 text-gray-400">{{  $t('information.plan.management') }}</p>

                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div class="mt-10">
                            <label for="logo" class="block text-sm/6 font-medium text-white">{{ $t('logo')}}</label>
                            <div class="mt-2 flex items-center gap-x-3">
                                <img v-if="imageUrl" :src="imageUrl" class="size-12" />
                                <button v-if="imageUrl" type="button" class="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-white/20" @click="imageUrl='';document.getElementById('logo').click()">{{ $t('change')}}</button>
                                <UserCircleIcon v-if="!imageUrl" class="size-12 text-gray-500" aria-hidden="true" />
                                <input v-if="!imageUrl" type="file" id="logo" ref="logo" name="logo" @change="photoUploaded($event.target.files)">
                            </div>
                        </div>
<!--                        <Input name="name" type="text" label="Name" size="30" v-model="form.name" placeholder="Class name" :error="form.errors.name"/>-->
<!--                        <TextArea name="description" label="Description" :rows=5 v-model="form.description" placeholder="Description" :error="form.errors.description" instructions="Write a few sentences describing the class."/>-->
                    </div>
                </div>
            </div>
<!--            Company info tab-->
            <div class="space-y-12" v-if="visibleTab === 'companyInfo'">
                <div class="border-b border-white/10 pb-12">
                    <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.company.info')}}</p>

                    <div class="mt-10">
                        <Input name="tenant_name" type="text" :label="$t('company.display.name')" size="30" v-model="form.tenant_name" :placeholder="$t('company.display.name')" :error="form.errors.tenant_name"/>
                        <p class="text-xs text-gray-500">{{ $t('information.company.display.name')}}</p>

                        <Input name="company_name" type="text" :label="$t('company.legal.name')" size="30" v-model="form.company_name" :placeholder="$t('company.legal.name')" :error="form.errors.company_name"/>
                        <p class="text-xs text-gray-500">{{ $t('information.company.legal.name')}}</p>

                        <Input name="company_address" type="text" :label="$t('address')" size="30" v-model="form.company_address" :placeholder="$t('address')" :error="form.errors.company_address"/>
                        <p class="text-xs text-gray-500">{{ $t('information.company.address')}}</p>

                        <Input name="company_city" type="text" :label="$t('city')" size="30" v-model="form.company_city" :placeholder="$t('city')" :error="form.errors.company_city"/>
                        <p class="text-xs text-gray-500">{{ $t('information.company.city')}}</p>

                        <Input name="company_state" type="text" :label="$t('state')" size="30" v-model="form.company_state" :placeholder="$t('state')" :error="form.errors.company_state"/>
                        <p class="text-xs text-gray-500">{{ $t('information.company.state')}}</p>

                        <Input name="company_zip" type="text" :label="$t('zip.code')" size="30" v-model="form.company_zip" :placeholder="$t('zip.code')" :error="form.errors.company_zip"/>
                        <p class="text-xs text-gray-500">{{ $t('information.company.zip')}}</p>

                        <Select name="country" :label="$t('country')" size="30" v-model="form.country" :options="countries" :error="form.errors.country"/>

                        <Input name="company_fiscal_code" type="text" :label="$t('fiscal.code')" size="30" v-model="form.company_fiscal_code" :placeholder="$t('fiscal.code')" :error="form.errors.company_fiscal_code"/>
                        <p class="text-xs text-gray-500">{{ $t('information.company.fiscal.code')}}</p>

                        <Input name="company_vat_code" type="text" :label="$t('vat.code')" size="30" v-model="form.company_vat_code" :placeholder="$t('vat.code')" :error="form.errors.company_vat_code"/>
                        <p class="text-xs text-gray-500">{{ $t('information.company.vat.code')}}</p>

                        <Input name="company_registration_number" type="text" :label="$t('registration.number')" size="30" v-model="form.company_registration_number" :placeholder="$t('registration.number')" :error="form.errors.company_registration_number"/>
                        <p class="text-xs text-gray-500">{{ $t('information.company.registration.number')}}</p>

                        <Select name="currency" :label="$t('currency')" v-model="form.currency" :error="form.errors.currency" :options="[{ value: 'RON', label: 'RON'}]">
                        </Select>
                    </div>
                </div>

            </div>
<!--            Contact info -->
            <div class="space-y-12" v-if="visibleTab === 'contactInfo'">
                <div class="border-b border-white/10 pb-12">
                    <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.contact.info')}}</p>

                    <div class="mt-10">
                        <Input name="company_phone" type="text" :label="$t('phone.number')" size="30" v-model="form.company_phone" :placeholder="$t('phone.number')" :error="form.errors.company_phone"/>
                        <p class="text-xs text-gray-500">{{ $t('information.contact.phone')}}</p>

                        <Input name="company_email" type="text" :label="$t('email.address')" size="30" v-model="form.company_email" :placeholder="$t('email.address')" :error="form.errors.company_email"/>
                        <p class="text-xs text-gray-500">{{ $t('information.contact.email')}}</p>

                        <Input name="company_website" type="text" :label="$t('website')" size="30" v-model="form.company_website" :placeholder="$t('website')" :error="form.errors.company_website"/>
                        <p class="text-xs text-gray-500">{{ $t('information.contact.website')}}</p>

                        <Input name="company_facebook" type="text" label="Facebook" size="30" v-model="form.company_facebook" placeholder="Facebook" :error="form.errors.company_facebook"/>
                        <p class="text-xs text-gray-500">{{ $t('information.contact.facebook')}}</p>

                        <Input name="company_twitter" type="text" label="Twitter" size="30" v-model="form.company_twitter" placeholder="Twitter" :error="form.errors.company_twitter"/>
                        <p class="text-xs text-gray-500">{{ $t('information.contact.twitter')}}</p>

                        <Input name="company_instagram" type="text" label="Instagram" size="30" v-model="form.company_instagram" placeholder="Instagram" :error="form.errors.company_instagram"/>
                        <p class="text-xs text-gray-500">{{ $t('information.contact.instagram')}}</p>

                        <Input name="company_linkedin" type="text" label="LinkedIn" size="30" v-model="form.company_linkedin" placeholder="LinkedIn" :error="form.errors.company_linkedin"/>
                        <p class="text-xs text-gray-500">{{ $t('information.contact.linkedin')}}</p>

                        <Input name="company_youtube" type="text" label="YouTube" size="30" v-model="form.company_youtube" placeholder="YouTube" :error="form.errors.company_youtube"/>
                        <p class="text-xs text-gray-500">{{ $t('information.contact.youtube')}}</p>

                    </div>
                </div>

            </div>
<!--            Scheduling tab -->
            <div class="space-y-12" v-if="visibleTab === 'scheduling'">
                <div class="border-b border-white/10 pb-12">
                    <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.scheduling.info')}}</p>

                    <div class="mt-10">
                        <Input name="schedule_interval" type="number" :label="$t('schedule.interval')" size="30" v-model="form.schedule_interval" :placeholder="$t('schedule.interval')" :error="form.errors.schedule_interval" min="5" max="60" step="5"/>
                        <p class="text-xs text-gray-500">{{ $t('information.scheduling.interval.value',{value: form.schedule_interval})}}</p>
                    </div>
                </div>

            </div>
<!--            Team tab -->
            <div class="space-y-12" v-if="visibleTab === 'team'">
                <div class="border-b border-white/10 pb-12">
                    <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.team.members')}}</p>

                    <div class="mt-10">
                        <ul>
                        <li v-for="teamMember in teamMembers" :key="teamMember.id" class="pb-1">
                            {{ teamMember.user.name}} ({{ teamMember.user.email }}) <BackspaceIcon class="inline-block size-5 text-gray-500 cursor-pointer" @click="openRemoveModal(teamMember.user.id)" v-if="teamMembers.length>1 && teamMember.user.id!==page.props.user.id"/>
                        </li>
                        </ul>
                        <div v-if="teamMembers.length===0">
                            <p class="text-sm/6 text-gray-400">{{ $t('information.team.no.members')}}</p>
                        </div>
                        <button @click="openAddTeamMemberModal" type="button" class="mt-2 rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-white/20">{{ $t('team.member.add')}}</button>
                    </div>
                </div>

            </div>
<!--            Task management tab -->
            <div class="space-y-12" v-if="visibleTab === 'taskManagement'">
                <div class="border-b border-white/10 pb-12">
                    <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.task.management.info')}}</p>

                    <div class="mt-10">
                        <Input name="task_management_remind_before_deadline_days" type="number" :label="$t('task.management.remind.before.deadline.days')" size="30" v-model="form.tasks_remind_before_deadline_days" :placeholder="$t('task.management.remind.before.deadline.days')" :error="form.errors.task_management_remind_before_deadline_days" min="1" max="10" step="1"/>
                        <p class="text-xs text-gray-500">{{ $t('task.management.remind.before.deadline.days.description')}}</p>
                        <Input name="task_management_hide_completed_tasks_days" type="number" :label="$t('task.management.hide.completed.tasks.days')" size="30" v-model="form.tasks_hide_completed_tasks_days" :placeholder="$t('task.management.hide.completed.tasks.days')" :error="form.errors.task_management_hide_completed_tasks_days" min="1" max="365" step="1"/>
                        <p class="text-xs text-gray-500">{{ $t('task.management.hide.completed.tasks.days.description')}}</p>
                    </div>
                </div>

            </div>
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.settings')" as="button" class="text-sm/6 font-semibold text-white">{{
                         $t('discard.changes')
                    }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{  $t('save') }}
                </button>
            </div>
            <AddTeamMemberModal :open="addTeamMemberModalOpen" :teamMembers="teamMembers" @memberAdded="teamMemberAdded" @modalClose="closeAddTeamMemberModal"/>
            <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('team.member')" :message="$t('confirm.delete')+' '+$t('team.member')+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
                   @modal-close="closeRemoveModal" @modalConfirmed="removeTeamMember"/>
        </form>
    </Layout>
</template>

<template>
    <div class="flex justify-between rounded-md border-2 border-gray-700 bg-gray-800 p-4 m-2">
        <div class="w-full">
            <p>{{ props.task.subject }}</p>
            <div class="flex justify-between">
                <p :class="[task.priority==='urgent'? 'text-red-500' : task.priority==='high' ? 'text-orange-500' : 'text-gray-400','text-xs']">
                    <ArrowsUpDownIcon class="size-4 inline"/>
                    {{ $t(task.priority) }}
                </p>
                <p v-if="props.task.labels.length>0" class="text-gray-400 text-xs">
                    <TagIcon class="size-4 inline mr-1"/>
                    {{ props.task.labels.map(v => v.label).join(', ') }}
                </p>
            </div>
        </div>
        <img class="size-8 rounded-full"
             :src="task.assigned_to!==null ? getAvatarUrl(task.assigned_to) : getAvatarUrl(null)"
             :alt="task.assigned_to!==null ? task.assigned_to.name : ''"/>
    </div>
</template>

<script setup>
import CryptoJS from "crypto-js";
import {ArrowsUpDownIcon, TagIcon} from "@heroicons/vue/24/outline";

const props = defineProps({
    task: {
        type: Object,
        required: true
    },
})


function getAvatarUrl(user) {
    if (!user || !user.email) {
        return 'https://www.gravatar.com/avatar/?d=mp';
    }
    const hash = CryptoJS.SHA256(user.email.toLowerCase().trim()).toString(CryptoJS.enc.Hex);
    return `https://www.gravatar.com/avatar/${hash}?d=mp`;
}
</script>

<template>
    <div class="bg-gray-600 rounded-2xl mt-4">
        <div>
            <h2 class="sr-only">{{ $t('worklog') }}</h2>
            <div class="">
                <div v-for="(workItem, idx) in worklog" :key="workItem.id"
                     :class="[idx === 0 ? '' : 'border-t border-gray-200', 'flex','space-x-4','text-sm','text-gray-500','items-center']">
                    <div class="flex-none pl-1 pt-1">
                        <img :src="getAvatarUrl(workItem.user)" alt="" class="size-10 rounded-full bg-gray-100"/>
                    </div>
                    <div class="grow">
                        <h3 class="inline-block font-medium text-gray-400">{{ workItem.user.name }}</h3> <span
                        class="text-xs text-gray-500" :title="dayjs(workItem.created_at).format('YYYY-MM-DD HH:mm:ss')">
                            <time :datetime="workItem.created_at">{{ formatDate(workItem.created_at) }}</time>
                        </span>
                        <div class="mt-1 text-sm/6 text-gray-100 whitespace-pre-wrap">
                            <span class="text-gray-400">{{ $t('worklog.time') }}: </span>
                            <span class="text-gray-100">{{ workItem.time_spent<60 ? $t('minute',{value: workItem.time_spent}) : $t('hour',{value: Math.floor(workItem.time_spent/60)}) + (workItem.time_spent%60!==0 ? ' '+ $t('minute',{value: Math.floor(workItem.time_spent%60)}) : '') }}</span>
                            </div>
                        <div class="mt-1 text-sm/6 text-gray-100 whitespace-pre-wrap" v-html="workItem.description"/>
                    </div>
                    <div v-if="page.props.accessType==='admin' || page.props.user.id===workItem.user.id"
                         class="flex-none">
                        <button type="button"
                                class="rounded-md bg-red-900/50 px-1 py-1 mx-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                                @click="openRemoveModal(workItem.id)">
                            <TrashIcon class="h-5 w-5 text-gray-100" aria-hidden="true"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-2 px-2">
            <form @submit.prevent="submit">
                <div class="flex w-full items-center">
                    <Input name="time_spent" type="number" min="1" max="1440"  class="grow mt-1 mr-2" v-model="form.time_spent"
                              :placeholder="$t('add.time.spent')" :error="form.errors.time_spent"/>
                    <TextArea name="description" :rows="1" class="grow mt-1 mr-2 w-full" v-model="form.description"
                              :placeholder="$t('add.time.description')" :error="form.errors.description"/>
                    <button type="submit"
                            class="rounded-md bg-indigo-500 px-1 py-1 mt-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                        <PaperAirplaneIcon class="h-5 w-5 text-gray-100" aria-hidden="true"/>
                    </button>
                </div>
                <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('worklog').toLowerCase()"
                       :message="$t('confirm.delete')+' '+$t('worklog').toLowerCase()+'? '+$t('data.permanently.removed')"
                       :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
                       @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
            </form>
        </div>
    </div>
</template>

<script setup>
import CryptoJS from "crypto-js";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {useForm, usePage} from "@inertiajs/vue3";
import {inject, ref} from "vue";
import axios from "axios";
import {PaperAirplaneIcon, TrashIcon} from "@heroicons/vue/24/solid/index.js";
import Alert from "../../../Components/Alert.vue";
import Input from "../../../Components/Form/Input.vue";
import TextArea from "../../../Components/Form/TextArea.vue";

const page = usePage();
const route = inject('route');
const props = defineProps({
    task: {
        type: Object,
        required: true
    }
})
let worklog = ref(props.task.work_logs)
const form = useForm({
    time_spent: '',
    desription: '',
})

function getAvatarUrl(user) {
    if (!user || !user.email) {
        return 'https://www.gravatar.com/avatar/?d=mp';
    }
    const hash = CryptoJS.SHA256(user.email.toLowerCase().trim()).toString(CryptoJS.enc.Hex);
    return `https://www.gravatar.com/avatar/${hash}?d=mp`;
}

function formatDate(dateString) {
    const date = dayjs(dateString);
    dayjs.extend(relativeTime)
    return date.fromNow();
}

function submit() {
    axios.put(route('main.tasks.workLog', props.task.id), {
        time_spent: form.time_spent,
        description: form.description,
    }).then((response) => {
        worklog.value.push(response.data.workLog)
        form.reset()
    }).catch((error) => {
        console.error('Error adding workLog:', error);
    });
}


let deleteModalOpen = ref(false)
let deleteWorkLogId = ref(null)

function openRemoveModal(id) {
    deleteWorkLogId.value = id
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteWorkLogId.value = null
    deleteModalOpen.value = false
}

function remove() {
    let deleteId = deleteWorkLogId.value
    axios.delete(route('main.tasks.workLog.delete', {task: props.task.id, workLog: deleteId}))
        .then(() => {
            worklog.value = worklog.value.filter(comment => comment.id !== deleteId)
        })
        .catch((error) => {
            console.error('Error deleting comment:', error);
        });
    closeRemoveModal()
}

</script>

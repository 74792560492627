<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, usePage} from "@inertiajs/vue3";
import {FunnelIcon, PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {Head} from '@inertiajs/vue3'
import {capitalize, inject, ref} from 'vue';
import {vDraggable} from 'vue-draggable-plus'
import TaskCard from "./TaskCard.vue";
import axios from "axios";
import SelectMultipleLabel from "../../../Components/Form/SelectMultipleLabel.vue";
import SelectMultipleModel from "../../../Components/Form/SelectMultipleModel.vue";
import {ArrowsUpDownIcon, TagIcon, UserIcon} from "@heroicons/vue/24/outline";

const route = inject('route');

const pageTree = [
    {name: 'task.management', href: route('main.tasks.index'), current: true}
]
const page = usePage()
const props = defineProps({
    tasks: {
        type: Array,
        required: true,
    },
    availableLabels: {
        type: Array,
        required: true,
    },
    assignable: {
        type: Array,
        required: true,
    },
    filterLabels: {
        type: Array,
        required: true,
    },
    filterAssignee: {
        type: Array,
        required: true,
    },
    filterPriority: {
        type: Array,
        required: true,
    },
})

let tasksToDo = ref(props.tasks.filter(task => task.status === 'to do'))
let tasksPending = ref(props.tasks.filter(task => task.status === 'pending'))
let tasksInProgress = ref(props.tasks.filter(task => task.status === 'inProgress'))
let tasksDone = ref(props.tasks.filter(task => task.status === 'done'))


function onUpdate(el) {
    console.log('Updated', el);
}

function onAdd(el) {
    axios.patch(route('main.tasks.change.status', el.data.id), {
        status: el.to.role
    }).then(() => {

    }).catch((error) => {
        console.error('Error changing status:', error);
    });
}

function onRemove() {
}

import {useTranslate} from '@tolgee/vue';

const t = useTranslate();

let allowedAssignees = ref(props.assignable)
allowedAssignees.value.push({
    id: 0,
    name: t.t.value('unassigned')
})

let alllowedLabels = ref(props.availableLabels)
alllowedLabels.value.push("none")

let currentFilterLabels = ref(props.filterLabels);
let currentFilterAssignee = ref(props.filterAssignee);
let currentFilterPriority = ref(props.filterPriority);

function updateFilters() {
    router.visit(route('main.tasks.index'), {
        method: 'get',
        data: {
            filterAssignee: currentFilterAssignee.value,
            filterLabels: currentFilterLabels.value,
            filterPriority: currentFilterPriority.value
        },
        preserveScroll: true,
    });
}

</script>

<template>
    <Head :title="$t('task.management')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('main.tasks.add')" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add task"/>
            </Link>
        </div>
        <div class="border-1 border-gray-700 bg-gray-800 rounded-lg">
            <div class="flex flex-row gap-4 mb-4 items-center p-4">
                <FunnelIcon class="size-6 text-gray-500 mt-2" aria-hidden="true" aria-label="Filter"/>
                <SelectMultipleModel name="filterAssignee" :options="allowedAssignees" v-model="currentFilterAssignee"
                                     @update:modelValue="updateFilters" class="w-64" :icon="UserIcon" :hidden="page.props.accessType==='trainer'"/>
                <SelectMultipleLabel name="filterPriority" :options="['niceToHave','low','medium','high','urgent']"
                                     v-model="currentFilterPriority" :translate="true"
                                     @update:modelValue="updateFilters" class="w-64" :icon="ArrowsUpDownIcon"/>
                <SelectMultipleLabel name="filterLabels" :options="alllowedLabels" v-model="currentFilterLabels"
                                     @update:modelValue="updateFilters" class="w-64" :icon="TagIcon"/>
            </div>
            <div class="grid grid-cols-4 divide-x divide-gray-700 min-h-svh">
                <div>
                    <p class="text-gray-200 font-bold w-full text-center border-y-1 border-gray-600">{{
                            $t('todo')
                        }}</p>
                    <ul role="to do" class="flex h-full flex-col" v-draggable="[
            tasksToDo,{
            animation: 150,
            group:'tasks',
            onUpdate,
            onAdd,
            onRemove
            }
        ]">
                        <li v-for="task in tasksToDo" :key="task.id" class="w-full">
                            <Link :href="route('main.tasks.edit',task.id)">
                                <TaskCard :task="task"/>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div class="h-full flex flex-col flex-1">
                    <p class="text-gray-200 font-bold w-full text-center border-y-1  border-gray-600">
                        {{ capitalize($t('pending')) }}</p>
                    <ul role="pending" class="flex h-full flex-col" v-draggable="[
            tasksPending,{
            animation: 150,
            group:'tasks',
            onUpdate,
            onAdd,
            onRemove
            }
        ]">
                        <li v-for="task in tasksPending" :key="task.id" class="w-full">
                            <Link :href="route('main.tasks.edit',task.id)">
                                <TaskCard :task="task"/>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <p class="text-gray-200 font-bold w-full text-center border-y-1  border-gray-600">{{
                            $t('inProgress')
                        }}</p>
                    <ul role="inProgress" class="flex h-full flex-col" v-draggable="[
            tasksInProgress,{
            animation: 150,
            group:'tasks',
            onUpdate,
            onAdd,
            onRemove
            }
        ]">
                        <li v-for="task in tasksInProgress" :key="task.id" class="w-full">
                            <Link :href="route('main.tasks.edit',task.id)">
                                <TaskCard :task="task"/>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <p class="text-gray-200 font-bold w-full text-center border-y-1 border-gray-600">{{
                            $t('done')
                        }}</p>
                    <ul role="done" class="flex h-full flex-col" v-draggable="[
            tasksDone,{
            animation: 150,
            group:'tasks',
            onUpdate,
            onAdd,
            onRemove
            }
        ]">
                        <li v-for="task in tasksDone" :key="task.id" class="w-full">
                            <Link :href="route('main.tasks.edit',task.id)">
                                <TaskCard :task="task"/>
                            </Link>
                        </li>
                    </ul>
                </div>
                <!--        </ul>-->
            </div>
        </div>
    </Layout>
</template>

<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm, usePage} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import {inject, ref} from 'vue';
import SelectModel from "../../../Components/Form/SelectModel.vue";
import Input from "../../../Components/Form/Input.vue";
import TextArea from "../../../Components/Form/TextArea.vue";
import Alert from "../../../Components/Alert.vue";
import Comments from "./Comments.vue";
import SelectMultipleWithAdd from "../../../Components/Form/SelectMultipleWithAdd.vue";
import axios from "axios";
import {
    BriefcaseIcon, ChatBubbleLeftIcon
} from "@heroicons/vue/24/outline/index.js";
import {ChevronDownIcon} from "@heroicons/vue/16/solid/index.js";
import WorkLog from "./WorkLog.vue";

const route = inject('route');

const props = defineProps({
    assignable: {
        type: Array,
        required: true,
    },
    task: {
        type: Object,
        required: true,
    },
    labels: {
        type: Array,
        required: true,
    },
})
const page = usePage()
const pageTree = [
    {name: 'task.management', href: route('main.tasks.index'), current: false},
    {name: 'task.edit', href: route('main.tasks.edit',props.task.id), current: true},
]

const form = useForm({
    subject: props.task.subject,
    description: props.task.description,
    assigned_to: props.task.assigned_to ? props.task.assigned_to.id : null,
    status: props.task.status,
    estimated_time: props.task.estimated_time,
    deadline: props.task.deadline,
    priority: props.task.priority,
    labels: props.task.label_array,
})
function submit() {
    form.patch(route('main.tasks.patch',props.task.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.tasks.index'))
        }
    })
}

let deleteModalOpen = ref(false)

function openRemoveModal() {
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function remove() {
    closeRemoveModal()
    router.delete(route('main.tasks.delete',props.task.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.tasks.index'))
        }
    })
}

const labels = ref(props.labels)

function newLabelOption(label) {
    if (label==='none') {
        return
    }
    axios.put(route('main.tasks.label',props.task.id), {
        label: label
    }).then(response => {
        labels.value.push(response.data.label.label)
        form.labels.push(response.data.label.label)
    }).catch(error => {
        console.log(error)
    })
}
if (page.props.accessType==='trainer') {
    form.assigned_to = page.props.user.id
}

const tabs = ref([
    { name: 'comments', tabName: 'comments', icon: ChatBubbleLeftIcon, current: true },
    { name: 'worklog', tabName: 'worklog', icon: BriefcaseIcon, current: false },
])

function setActiveTab(tabName) {
    visibleTab.value = tabName
    tabs.value.forEach(tab => {
        tab.current = tab.tabName === tabName
    })
}
let visibleTab = ref('comments')

</script>

<template>
    <Head :title="$t('task.edit')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <div class="space-y-12">
                <div class="border-b border-white/10 pb-12">
                    <div class="mt-10 grid grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-2">
                        <div class="flex flex-col gap-4">
                            <Input name="subject" type="text" :label="$t('task.subject')" size="30" v-model="form.subject" :placeholder="$t('task.subject')" :error="form.errors.subject"/>
                            <TextArea name="description" :label="$t('task.description')"  v-model="form.description" :placeholder="$t('task.description')" :error="form.errors.description"/>
                            <SelectModel name="assigned_to" :label="$t('assigned.to')" :options="props.assignable" :error="form.errors.assigned_to" v-model="form.assigned_to" :allow-none="true" :hidden="page.props.accessType==='trainer'"/>
                        </div>
                        <div class="flex flex-col gap-4">
                            <SelectModel name="status" :label="$t('task.status')" :options="[{id: 'to do', label: 'todo'},{id: 'pending', label: 'pending'}, {id: 'inProgress', label: 'inProgress'}, {id: 'done', label: 'done'}]" :error="form.errors.status" v-model="form.status"/>
                            <SelectModel name="priority" :label="$t('task.priority')" :options="[{id: 'niceToHave', label: 'niceToHave'}, {id: 'low', label: 'low'}, {id: 'medium', label: 'medium'}, {id: 'high', label: 'high'},{id: 'urgent', label: 'urgent'}]" :error="form.errors.priority" v-model="form.priority"/>
                            <SelectMultipleWithAdd name="labels[]" :label="$t('task.labels')" :options="labels" :error="form.errors.labels" v-model="form.labels" @addOption="newLabelOption"/>
                            <Input name="estimated_time" type="number" :label="$t('task.estimated.time')" v-model="form.estimated_time" :error="form.errors.estimated_time" :placeholder="$t('task.estimated.time')"/>
                            <Input name="deadline" type="date" :label="$t('task.deadline')" v-model="form.deadline" :error="form.errors.deadline" :placeholder="$t('task.deadline')"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <button type="button"
                        class="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        @click="openRemoveModal" :hidden="page.props.accessType==='trainer'">{{ $t('delete') }}</button>
                <Link :href="route('main.tasks.index')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>


            <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('task').toLowerCase()" :message="$t('confirm.delete')+' '+$t('task').toLowerCase()+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
                   @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
        </form>
        <div class="flex flex-row">
            <div class="grid grid-cols-1 sm:hidden">
                <select aria-label="Select a tab" class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600" @change="setActiveTab($event.target.value)">
                    <option v-for="tab in tabs" :key="tab.name" :selected="tab.current" :value="tab.tabName">{{ $t(tab.name) }}</option>
                </select>
                <ChevronDownIcon class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500" aria-hidden="true" />
            </div>
            <div class="hidden sm:block">
                <div class="border-b border-gray-200">
                    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                        <button v-for="tab in tabs" :key="tab.name" :class="[tab.current ? 'border-gray-100 text-gray-200' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-400', 'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium']" :aria-current="tab.current ? 'page' : undefined" @click.prevent="setActiveTab(tab.tabName)">
                            <component :is="tab.icon" :class="[tab.current ? 'text-gray-100' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 size-5']" aria-hidden="true" />
                            <span>{{ $t(tab.name) }}</span>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
        <Comments :task="task" v-if="visibleTab==='comments'"/>
        <WorkLog :task="task" v-if="visibleTab==='worklog'"/>

    </Layout>
</template>

<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {router, usePage} from "@inertiajs/vue3";
import {Head, Link} from '@inertiajs/vue3'
import {capitalize, inject, ref} from 'vue';
import {useTranslate} from "@tolgee/vue";
import {FunnelIcon, UserIcon} from "@heroicons/vue/24/outline/index.js";
import SelectModel from "../../../Components/Form/SelectModel.vue";
import Input from "../../../Components/Form/Input.vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";

const route = inject('route');
const t = useTranslate()
const pageTree = [
    {name: 'task.management', href: route('main.tasks.index'), current: false},
    {name: 'task.reports', href: route('main.tasks.reports'), current: true},
]
const page = usePage()
const props = defineProps({
    tasks: {
        type: Array,
        required: true,
    },
    totalTime: {
        type: Number,
        required: true,
    },
    totalTasks: {
        type: Number,
        required: true,
    },
    totalTasksDone: {
        type: Number,
        required: true,
    },
    totalTasksPending: {
        type: Number,
        required: true,
    },
    assignedId: {
        type: Number,
        required: true,
    },
    from: {
        type: String,
        required: true,
    },
    to: {
        type: String,
        required: true,
    },
    assignable: {
        type: Array,
        required: true,
    },
})


let allowedAssignees = ref(props.assignable)
allowedAssignees.value.push({
    id: 0,
    name: t.t.value('all'),
})

let currentFilterAssignee = ref(props.assignedId);
let currentFilterFrom = ref(props.from);
let currentFilterTo = ref(props.to);

function updateFilters() {
    router.visit(route('main.tasks.reports'), {
        method: 'get',
        data: {
            assignedId: currentFilterAssignee.value,
            from: currentFilterFrom.value,
            to: currentFilterTo.value,
        },
        preserveScroll: true,
    });
}

function getWorklogTotalTime(workLogs) {
    let totalTime = 0;
    workLogs.forEach(workLog => {
        totalTime += workLog.time_spent;
    });
    return totalTime;
}

function getTimePretty(time) {
    // workItem.time_spent<60 ? $t('minute',{value: workItem.time_spent}) : $t('hour',{value: Math.floor(workItem.time_spent/60)}) + (workItem.time_spent%60!==0 ? ' '+ $t('minute',{value: Math.floor(workItem.time_spent%60)}) : '')
    if (time < 60) {
        return t.t.value('minute', {value: time})
    } else {
        return t.t.value('hour', {value: Math.floor(time / 60)}) + (time % 60 !== 0 ? ' ' + t.t.value('minute', {value: Math.floor(time % 60)}) : '')
    }
}


function formatDate(dateString) {
    const date = dayjs(dateString);
    dayjs.extend(relativeTime)
    return date.fromNow();
}

</script>

<template>
    <Head :title="$t('task.management')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <div class="flex flex-row gap-4 mb-4 items-center p-4">
            <FunnelIcon class="size-6 text-gray-500 mt-2" aria-hidden="true" aria-label="Filter"/>
            <SelectModel name="filterAssignee" :options="allowedAssignees" v-model="currentFilterAssignee"
                         @update:modelValue="updateFilters" class="w-64" :icon="UserIcon"
                         :hidden="page.props.accessType==='trainer'"/>
            <Input name="from" type="date" v-model="currentFilterFrom" class="w-36" @update:modelValue="updateFilters"/>
            <Input name="to" type="date" v-model="currentFilterTo" class="w-36" @update:modelValue="updateFilters"/>
        </div>

        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-700">
                        <thead>
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                                ID
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                {{ capitalize($t('task')) }}
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                {{ capitalize($t('author')) }}
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                {{ $t('status') }}
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                {{ capitalize($t('priority')) }}
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                {{ $t('created.at') }}
                            </th>

                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                {{ capitalize($t('time.worked')) }}
                            </th>
                        </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-800">
                        <tr v-for="task in props.tasks" :key="task.id" class="even:bg-gray-700">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                <Link :href="route('main.tasks.edit', task.id)"
                                      class="text-blue-500 hover:text-blue-700">
                                    TASK-{{ task.id }}
                                </Link>
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                <Link :href="route('main.tasks.edit', task.id)"
                                      class="text-blue-500 hover:text-blue-700">
                                    {{ task.subject }}
                                </Link>
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                {{ task.created_by.name }}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{{ task.status }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{{ task.priority }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{{
                                    formatDate(task.created_at)
                                }}
                            </td>

                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                {{ getTimePretty(getWorklogTotalTime(task.work_logs)) }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="flex flex-row-reverse">
                        <div class="justify-end items-end">
                            {{ $t('total.tasks') }}: {{ props.totalTasks }}<br>
                            {{ $t('total.tasks.done') }}: {{ props.totalTasksDone }}<br>
                            {{ $t('total.tasks.pending') }}: {{ props.totalTasksPending }}<br>
                            {{ $t('total.time.worked') }}: {{ getTimePretty(props.totalTime) }}<br>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm, usePage} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import { inject } from 'vue';
import SelectModel from "../../../Components/Form/SelectModel.vue";
import Input from "../../../Components/Form/Input.vue";
import TextArea from "../../../Components/Form/TextArea.vue";

const route = inject('route');

const pageTree = [
    {name: 'task.management', href: route('main.tasks.index'), current: false},
    {name: 'task.add', href: route('main.tasks.add'), current: true},
]

const page = usePage()
const form = useForm({
    subject: '',
    description: '',
    assigned_to: '',
    status: 'pending',
    estimated_time: 2,
    deadline: '',
    priority: 'low',
})

function submit() {
    form.post(route('main.tasks.store'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.tasks.index'))
        }
    })
}
const props = defineProps({
    assignable: {
        type: Array,
        required: true,
    },
})

if (page.props.accessType==='trainer') {
    form.assigned_to = page.props.user.id
}
</script>

<template>
    <Head :title="$t('task.add')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <div class="space-y-12">
                <div class="border-b border-white/10 pb-12">
                    <div class="mt-10 grid grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-2">
                        <div class="flex flex-col gap-4">
                        <Input name="subject" type="text" :label="$t('task.subject')" size="30" v-model="form.subject" :placeholder="$t('task.subject')" :error="form.errors.subject"/>
                        <TextArea name="description" :label="$t('task.description')"  v-model="form.description" :placeholder="$t('task.description')" :error="form.errors.description"/>
                        <SelectModel name="assigned_to" :label="$t('assigned.to')" :options="props.assignable" :error="form.errors.assigned_to" v-model="form.assigned_to" :allow-none="true" v-if="page.props.accessType==='trainer'"/>
                        </div>
                        <div class="flex flex-col gap-4">
                        <SelectModel name="status" :label="$t('task.status')" :options="[{id: 'to do', label: 'todo'},{id: 'pending', label: 'pending'}, {id: 'inProgress', label: 'inProgress'}, {id: 'done', label: 'done'}]" :error="form.errors.status" v-model="form.status"/>
                        <SelectModel name="priority" :label="$t('task.priority')" :options="[{id: 'niceToHave', label: 'niceToHave'}, {id: 'low', label: 'low'}, {id: 'medium', label: 'medium'}, {id: 'high', label: 'high'},{id: 'urgent', label: 'urgent'}]" :error="form.errors.priority" v-model="form.priority"/>
                        <Input name="estimated_time" type="number" :label="$t('task.estimated.time')" v-model="form.estimated_time" :error="form.errors.estimated_time" :placeholder="$t('task.estimated.time')"/>
                        <Input name="deadline" type="date" :label="$t('task.deadline')" v-model="form.deadline" :error="form.errors.deadline" :placeholder="$t('task.deadline')"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.tasks.index')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>

        </form>
    </Layout>
</template>
